import * as React from 'react';

import {useLocation} from '@reach/router';
import {Helmet} from 'react-helmet';
import {EmailBanner, EditorialHomepageHero} from 'src/components/editorial';
import {BrandPromoBanner} from 'src/components/editorial/BrandPromoBanner';
import {CategoriesFeatureModule} from 'src/components/editorial/CategoriesFeatureModule';
import {FullWidthBrandCTA} from 'src/components/editorial/FullWidthBrandCTA';
import {HomeIconGrid} from 'src/components/editorial/HomeIconGrid';
import {ThreeGridArticles} from 'src/components/editorial/ThreeGridArticles';
import {Flex} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import ArticlesListTemplate from 'src/templates/articlesHomepage';
import EditorialTemplate from 'src/templates/editorial';
import {InsightsNavigation} from 'src/templates/insightsNavigation';
import {hero} from 'src/utils/copy';
import {sanityClient} from 'src/utils/sanity';
import styled from 'styled-components';
import useSWR from 'swr';

import {Maybe, SanityEditorial, SanityImgTitleIntro, SanityButtonSimple} from 'types/generated';

const ErrorWrapper = styled('div').attrs({
  pt: 'L',
  pb: 'L',
})`
  background-color: ${({theme}) => theme.colors.white};
`;

interface EditorialHomepageDraftProps {
  params: {
    slug: string;
  };
}
const groqQuery = `
*[
        _type=="editorialHomepage"
    ][0]{
        ...,
        heroSection{
            ...,
            featuredArticle->{
                ...,
                heroImage {
                    ...,
                    asset->
                    } 
            },
            secondArticle->{
                ...,
                heroImage {
                    ...,
                    asset->
                    } 
            },
            thirdArticle->{
                ...,
                heroImage {
                    ...,
                    asset->
                    } 
            },
        },
        homePageBuilder[]{
            ...,
            articleOne->{
                ...,
                heroImage {
                    ...,
                    asset->
                } 
            },
            articleTwo->{
                ...,
                heroImage {
                    ...,
                    asset->
                } 
            },
            articleThree->{
                ...,
                heroImage {
                    ...,
                    asset->
                } 
            }, 
            gridItem[]{
                ...,
                iconArticle->{
                    ...,
                },
                image {
                    ...,
                    asset->
                } 
            },    
        },
    }
`;

function getTopicData() {
  const topicGroqQuery = `
        *[
            _type=="topic"
        ][]{
            ...,
            relatedBenefitCta->{
                ...,
                icon{
                    ...,
                    asset->
                }
            },
            featuredArticles {
                ...,
                featuredArticle->{
                    ...,
                    heroImage {
                        ...,
                        asset->
                        } 
                },
                firstArticle->{
                    ...,
                    heroImage {
                        ...,
                        asset->
                        } 
                },
                secondArticle->{
                    ...,
                    heroImage {
                        ...,
                        asset->
                        } 
                },
                thirdArticle->{
                    ...,
                    heroImage {
                        ...,
                        asset->
                        } 
                },
            },
        }
    `;
  const {data, error} = useSWR([topicGroqQuery], query => sanityClient.fetch(query));

  if (error) {
    return (
      <ErrorWrapper>
        <Typography.H1>Error loading draft editorial:</Typography.H1>
        <Typography.P1>{JSON.stringify(error)}</Typography.P1>
      </ErrorWrapper>
    );
  }

  return data;
}

const EditorialHomepageDraftPage: React.FC<EditorialHomepageDraftProps> = ({params}: EditorialHomepageDraftProps) => {
  const {data, error} = useSWR([groqQuery], query => sanityClient.fetch(query));
  const topicData = getTopicData();
  const location = useLocation();
  if (error) {
    return (
      <ErrorWrapper>
        <Typography.H1>Error loading draft editorial:</Typography.H1>
        <Typography.P1>{JSON.stringify(error)}</Typography.P1>
      </ErrorWrapper>
    );
  }

  if (data === undefined) {
    return (
      <Container>
        <Typography.H2 mt="L" mb="L" color="white" textAlign="center">
          Loading draft preview
        </Typography.H2>
      </Container>
    );
  }

  if (data === null) {
    return (
      <Container>
        <Typography.P0 color="white">Sorry, we could not find a page with the provided slug</Typography.P0>
        <Typography.P0 color="white">
          If you believe this is an error, make sure you are logged into sanity.
        </Typography.P0>
      </Container>
    );
  }

  const pageBuilder = data?.homePageBuilder?.map(
    (item: {
      _type: string;
      heading: Maybe<string>;
      articleOne: Maybe<SanityEditorial>;
      articleTwo: Maybe<SanityEditorial>;
      articleThree: Maybe<SanityEditorial>;
      intro: Maybe<string>;
      gridItem: Maybe<Maybe<SanityImgTitleIntro>[]>;
      content: Maybe<string>;
      title: Maybe<string>;
      cta: Maybe<Maybe<SanityButtonSimple>[]>;
      allCategoriesFeature: any;
      subCopy: any;
      Heading: any;
      customizeEmailSignUp: any;
    }) => {
      if (item?._type === 'threeGridArticles') {
        return (
          <ThreeGridArticles
            heading={item?.heading}
            articleOne={item?.articleOne}
            articleTwo={item?.articleTwo}
            articleThree={item?.articleThree}
          />
        );
      } else if (item?._type === 'editorialHomeIconGrid') {
        return <HomeIconGrid heading={item?.heading} intro={item?.intro} gridItem={item?.gridItem} />;
      } else if (item?._type === 'brandPromoBanner') {
        return <BrandPromoBanner content={item?.content} title={item?.title} cta={item?.cta} />;
      } else if (item?._type === 'fullWidthBrandedCta') {
        return <FullWidthBrandCTA content={item?.content} cta={item?.cta} />;
      } else if (item?._type === 'allCatFeatArticles') {
        if (item?.allCategoriesFeature) {
          if (topicData) {
            return <CategoriesFeatureModule topics={topicData} />;
          }
        }
      } else if (item?._type === 'emailSignUp') {
        return (
          <EmailBanner
            id="newsletter-form"
            subCopy={item?.subCopy}
            Heading={item?.Heading}
            customizeEmailSignUp={item?.customizeEmailSignUp}
            fullWidth={true}
            homepage={true}
          />
        );
      }
    },
  );

  return (
    <div className="bg-white pt-8 md:pt-0">
      <InsightsNavigation />
      {data?.customizeHeroSection && (
        <EditorialHomepageHero
          featuredArticle={data?.heroSection?.featuredArticle}
          secondArticle={data?.heroSection?.secondArticle}
          thirdArticle={data?.heroSection?.thirdArticle}
        />
      )}
      {pageBuilder}
    </div>
  );
};

const Container = styled(Flex).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  backgroundColor: 'black',
})``;

export default EditorialHomepageDraftPage;
